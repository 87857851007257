import axios from 'axios';
import { API_ENDPOINT } from '../consts/urls';
import { getToken } from '../utils/localStorage';

const apiClient = axios.create({
    baseURL: API_ENDPOINT,
});

apiClient.interceptors.request.use(async (config) => {
    console.log('file: apiClient.js:10 -> apiClient.interceptors.request.use -> config:', config);
    const token = getToken();

    if (token && config.url !== '/files/upload/') {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
});

export default apiClient;
