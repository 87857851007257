const CloseIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 1L1 9" stroke="#C4C4C4" />
    <path d="M1 1L9 9" stroke="#C4C4C4" />
  </svg>
);

export default CloseIcon;
